// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-enrolment-js": () => import("./../../../src/pages/enrolment.js" /* webpackChunkName: "component---src-pages-enrolment-js" */),
  "component---src-pages-global-university-accreditation-js": () => import("./../../../src/pages/global-university/accreditation.js" /* webpackChunkName: "component---src-pages-global-university-accreditation-js" */),
  "component---src-pages-global-university-bachelor-of-arts-in-christian-education-js": () => import("./../../../src/pages/global-university/bachelor-of-arts-in-christian-education.js" /* webpackChunkName: "component---src-pages-global-university-bachelor-of-arts-in-christian-education-js" */),
  "component---src-pages-global-university-bachelor-of-arts-in-intercultural-studie-js": () => import("./../../../src/pages/global-university/bachelor-of-arts-in-intercultural-studie.js" /* webpackChunkName: "component---src-pages-global-university-bachelor-of-arts-in-intercultural-studie-js" */),
  "component---src-pages-global-university-bachelor-of-arts-js": () => import("./../../../src/pages/global-university/bachelor-of-arts.js" /* webpackChunkName: "component---src-pages-global-university-bachelor-of-arts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-homestay-js": () => import("./../../../src/pages/info/homestay.js" /* webpackChunkName: "component---src-pages-info-homestay-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-school-pld-gateway-careers-gateway-programmes-js": () => import("./../../../src/pages/school-pld-gateway/careers-gateway-programmes.js" /* webpackChunkName: "component---src-pages-school-pld-gateway-careers-gateway-programmes-js" */),
  "component---src-pages-school-pld-gateway-professional-learning-development-js": () => import("./../../../src/pages/school-pld-gateway/professional-learning-development.js" /* webpackChunkName: "component---src-pages-school-pld-gateway-professional-learning-development-js" */),
  "component---src-pages-study-js": () => import("./../../../src/pages/study.js" /* webpackChunkName: "component---src-pages-study-js" */),
  "component---src-pages-subject-custom-content-advanced-fundamentals-of-ai-in-education-js": () => import("./../../../src/pages/subject/customContent/AdvancedFundamentalsOfAiInEducation.js" /* webpackChunkName: "component---src-pages-subject-custom-content-advanced-fundamentals-of-ai-in-education-js" */),
  "component---src-pages-subject-custom-content-custom-content-js": () => import("./../../../src/pages/subject/customContent/customContent.js" /* webpackChunkName: "component---src-pages-subject-custom-content-custom-content-js" */),
  "component---src-pages-subject-custom-content-custom-course-content-js": () => import("./../../../src/pages/subject/customContent/customCourseContent.js" /* webpackChunkName: "component---src-pages-subject-custom-content-custom-course-content-js" */),
  "component---src-pages-subject-custom-content-dow-form-js": () => import("./../../../src/pages/subject/customContent/dowForm.js" /* webpackChunkName: "component---src-pages-subject-custom-content-dow-form-js" */),
  "component---src-pages-subject-custom-content-fundamentals-of-ai-in-education-js": () => import("./../../../src/pages/subject/customContent/FundamentalsOfAiInEducation.js" /* webpackChunkName: "component---src-pages-subject-custom-content-fundamentals-of-ai-in-education-js" */),
  "component---src-pages-subject-custom-content-introduction-of-ai-creation-js": () => import("./../../../src/pages/subject/customContent/IntroductionOfAiCreation.js" /* webpackChunkName: "component---src-pages-subject-custom-content-introduction-of-ai-creation-js" */),
  "component---src-pages-subject-custom-content-nz-financial-services-regulatory-framework-js": () => import("./../../../src/pages/subject/customContent/NZFinancialServicesRegulatoryFramework.js" /* webpackChunkName: "component---src-pages-subject-custom-content-nz-financial-services-regulatory-framework-js" */),
  "component---src-pages-subject-custom-content-tikanga-maori-js": () => import("./../../../src/pages/subject/customContent/tikangaMaori.js" /* webpackChunkName: "component---src-pages-subject-custom-content-tikanga-maori-js" */),
  "component---src-pages-subject-qualification-js": () => import("./../../../src/pages/subject/qualification.js" /* webpackChunkName: "component---src-pages-subject-qualification-js" */),
  "component---src-pages-subject-subject-page-js": () => import("./../../../src/pages/subject/subjectPage.js" /* webpackChunkName: "component---src-pages-subject-subject-page-js" */),
  "component---src-pages-subjects-js": () => import("./../../../src/pages/subjects.js" /* webpackChunkName: "component---src-pages-subjects-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-ui-js": () => import("./../../../src/pages/ui.js" /* webpackChunkName: "component---src-pages-ui-js" */)
}

